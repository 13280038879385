var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "c--shared_header_item header-item",
    attrs: {
      "data-testid": `${_vm.text}-header-language`
    },
    on: {
      "click": _vm.clickHandler
    }
  }, [!!_vm.image.length ? _c('i', {
    class: [_vm.image, 'tw-text-zinc-500 tw-text-4', {
      'mr-10 mr-md-5px': _vm.text.length > 0 && (_vm.dir === 'ltr' || _vm.dir === ''),
      'ml-10 ml-md-5px': _vm.text.length > 0 && _vm.dir === 'rtl'
    }]
  }) : _vm._e(), _vm._v(" "), _vm.text.length > 0 ? [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }