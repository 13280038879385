<template>
  <span class="c--shared_header_item header-item" :data-testid="`${text}-header-language`" @click="clickHandler">
    <i
      v-if="!!image.length"
      :class="[
        image,
        'tw-text-zinc-500 tw-text-4',
        {
          'mr-10 mr-md-5px': text.length > 0 && (dir === 'ltr' || dir === ''),
          'ml-10 ml-md-5px': text.length > 0 && dir === 'rtl',
        },
      ]"
    />
    <template v-if="text.length > 0">
      {{ text }}
    </template>
  </span>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      image: {
        type: String,
        required: false,
        default: '',
      },
      isImageClass: {
        type: Boolean,
        required: false,
        default: false,
      },
      text: {
        type: String,
        required: false,
        default: '',
      },
      dir: {
        type: String,
        required: false,
        default: '',
      },
    },
    methods: {
      clickHandler() {
        if (this?.item?.gtm) {
          let url = '/'

          if (this.item?.url?.name) {
            url = this.localePath({ name: this.item?.url?.name })
          } else {
            url = this.localePath({ path: this.item?.url })
          }

          this.$utils.sendEvent('mainNavigation', {
            text: this.item?.text,
            url,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .header-item {
    color: #4a4a4a;
    font-size: 0.9375rem;
    letter-spacing: 0;
    line-height: 1.4;
    font-weight: 400;
    white-space: nowrap;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }
</style>
